"use client";

import { allQueryKeys } from "#app/_api/allQueryKeys";
import { type GetNotificationData } from "#app/_api/external-bol-notification-service/get-notification";
import { patchNotification } from "#app/_api/external-bol-notification-service/patch-notification";
import { logError } from "#app/lib/logger";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import NextLink from "next/link";
import { isMaintenanceNotification } from "./my-updates.utils";
import { MyUpdatesDialogRead } from "./MyUpdatesDialogRead";

export function MyUpdatesButtonRead({
  children,
  className,
  data,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  data: Required<
    Pick<
      GetNotificationData[number],
      | "created"
      | "icon"
      | "longMessage"
      | "shortMessage"
      | "typeId"
      | "updateId"
      | "webRoute"
    >
  >;
  href?: string;
  onClick?: () => void;
}) {
  const queryClient = useQueryClient();

  const { queryKey } =
    allQueryKeys.externalBolNotificationService.notification();

  const mutation = useMutation({
    mutationFn: patchNotification,
    // When mutate is called:
    onMutate: async (params) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (old: GetNotificationData) =>
        old.map((t) => ({
          ...t,
          isRead: t.updateId === params.body.updateId ? true : t.isRead,
        })),
      );
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, params) => {
      logError(err);
      queryClient.setQueryData(queryKey, (old: GetNotificationData) =>
        old.map((t) => ({
          ...t,
          isRead: t.updateId === params.body.updateId ? false : t.isRead,
        })),
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const handleClick = () => {
    mutation.mutate({
      body: { isRead: true, typeId: data.typeId, updateId: data.updateId },
    });
    onClick?.();
  };

  if (isMaintenanceNotification(data.typeId)) {
    return (
      <MyUpdatesDialogRead data={data} onClose={handleClick}>
        <button type="button" className={className}>
          {children}
        </button>
      </MyUpdatesDialogRead>
    );
  }

  if (data.webRoute.startsWith("/")) {
    return (
      <NextLink
        href={data.webRoute}
        onClick={() => handleClick()}
        className={className}
      >
        {children}
      </NextLink>
    );
  }

  if (data.webRoute.startsWith("http")) {
    return (
      <a
        href={data.webRoute}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleClick()}
        className={className}
      >
        {children}
      </a>
    );
  }

  logError(`Invalid route: ${data.webRoute}`);
  return null;
}
