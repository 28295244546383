import { cx } from "#ui/style.utils";
import { formatMyUpdatedDateTime } from "./my-updates.utils";

export function MyUpdatesDateTime({
  className,
  value,
  formatStr,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, "children"> & {
  value: Parameters<typeof formatMyUpdatedDateTime>[0];
  formatStr?: Parameters<typeof formatMyUpdatedDateTime>[1];
}) {
  return (
    <div {...props} className={cx("text-sm text-shade-70", className)}>
      {formatMyUpdatedDateTime(value, formatStr)}
    </div>
  );
}
